import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
  clearProdCompareList,
  fetchProductDetailsData,
  getProductsByMaterialNameSuccess,
  setCurrentProductSku,
  setOpenToast,
  setProdCompareData,
  setSelectedProductsDownload,
} from "../../../state/actions";
import Link from "@mui/material/Link";
import { ROUTES_LABEL } from "../../../constants/routesLabels";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./resellerProdContent.module.scss";
import {
  getAvailabilityDetails,
} from "../../helper/util";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Typography,
} from "@mui/material";
import { TOAST_SEVERITY } from "../../../constants/commonConstants";
import CustomButton from "../../atoms/customButton";
var _ = require("lodash");
const noProduct = require("../../../assets/images/no_prod_tn.gif");
export const ResellerProdContent = ({parent,searchResult}:any) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const categoryNames = useParams()["*"];
  const redirectToDataDownload = () => {
    dispatch(getProductsByMaterialNameSuccess({}));
    dispatch(setSelectedProductsDownload(selectedProducts));
    navigate(`/${ROUTES_LABEL.DATA_DOWNLOAD}/${ROUTES_LABEL.DIGITAL_ASSET}`);
  };

  const productContentData = useSelector(
    (state: any) => state?.productReducer?.productContentData
  );
  const [showCompare, setShowCompare] = useState(false);

  useEffect(() => {
    if (parent==="globalSearch") {
      
      let availableDetailsCount = 0;
      const updatedProduct = searchResult?.map(
        (product: any) => Object.values(product)[0]
      );
      updatedProduct?.forEach((prod: any) => {
        if (prod?.product?.index?.uri) {
          availableDetailsCount++;
        }
      });
      if (availableDetailsCount >= 2) setShowCompare(true);
      else setShowCompare(false);

      setProdContentData(updatedProduct);
    }
    else if (!_.isEmpty(productContentData) && parent!=="globalSearch") {
      let prodList: any = [];
      let availableDetailsCount = 0;
      productContentData?.prodList?.forEach((prod: any) => {
        prodList.push(prod);
        if (prod?.product?.index?.uri) {
          availableDetailsCount++;
        }
      });
      if (availableDetailsCount >= 2) setShowCompare(true);
      else setShowCompare(false);
      setProdContentData(prodList);
    }
  }, [productContentData,searchResult]);
  const [prodContentData, setProdContentData] = useState<any>();

  const redirectToDetails = (product: any) => {
    dispatch(fetchProductDetailsData({ productNames: product?.product?.index?.materialName }));
    dispatch(setCurrentProductSku(product));
    categoryNames? navigate(`/${ROUTES_LABEL.PRODUCT_DETAILS}/${categoryNames}`): navigate(`/${ROUTES_LABEL.PRODUCT_DETAILS}`);
  };

  const descriptionHanlder = (descString: any) => {
    if (descString?.length > 55) {
      return descString?.substring(0, 55) + "...";
    } else return descString;
  };

  const prodCategoryResponse = useSelector(
    (state: any) => state?.commonReducer?.prodCategoryResponse
  );

  const findDisplayName = (categoryId: any) => {
    const categories: any =
      prodCategoryResponse?.data?.catalogList[0]?.children;
    for (const mainCategory of categories) {
      if (mainCategory.id === categoryId) {
        return mainCategory?.displayName;
      }
      if (mainCategory.children && mainCategory.children.length > 0) {
        for (const subCategory of mainCategory.children) {
          if (
           subCategory.id === categoryId
          ) {
            return subCategory.displayName;
          }
          if (subCategory.children && subCategory.children.length > 0) {
            for (const subSubCategory of subCategory.children) {
              if (
                subSubCategory.id === categoryId
              ) {
                return subSubCategory.displayName;
              }
            }
          }
        }
      }
    }
    return t("dnet.products.section");
  };

  const [selectedProducts, setSelectedProducts] = useState<any>([]);
  const checkHandler = (e: any, data: any, index: any) => {
    let prod = {
      index,
      prodData: data,
      prodDetails: {},
      availabilityDetails: {},
    };
    if (e?.target?.checked) {
      setSelectedProducts([...selectedProducts, prod]);
    } else {
      setSelectedProducts(
        selectedProducts.filter((item: any) => item.index !== index)
      );
    }
  };

  const productCompareList = useSelector(
    (state: any) => state?.productReducer?.productCompareList
  );

  useEffect(() => {
    if (productCompareList?.length > 0) {
      selectedProducts.forEach((ele: any) => {
        ele.prodDetails = productCompareList?.filter(
          (prod: any) =>
            prod?.materialName ===
            ele?.prodData?.product?.definition?.materialName
        )[0];
        ele.availabilityDetails = getAvailabilityDetails(ele?.prodData);
      });
      let tabList: any = [];
      productCompareList.forEach((ele: any) => {
        if (ele?.featureBenefits?.length > 0) tabList.push("feature");
        if (ele?.proBenefit?.length > 0) tabList.push("benefit");
        if (ele?.techSpecs?.length > 0) tabList.push("spec");
      });
      if (tabList?.length === 0) {
        dispatch(
          setOpenToast({
            openToast: false,
            severity: TOAST_SEVERITY.ERROR,
            message: t("dnet.no_info_for_comparison.text"),
          })
        );
      } else {
        dispatch(
          setProdCompareData({
            showFeature: tabList?.includes("feature"),
            showBenefits: tabList?.includes("benefit"),
            showTech: tabList?.includes("spec"),
            selectedProducts,
          })
        );
        dispatch(clearProdCompareList({}));
        categoryNames ? navigate(`/${ROUTES_LABEL.PRODUCT_COMPARE}/${categoryNames}`): navigate(`/${ROUTES_LABEL.PRODUCT_COMPARE}`) ;
      }
    }
  }, [productCompareList]);

  const redirectToCompare = () => {
    let productNames: any = [];
    selectedProducts.forEach((ele: any) => {
      if (ele?.prodData?.product?.index?.materialName)
        productNames.push(ele?.prodData?.product?.index?.materialName);
    });
    if (productNames?.length >= 2) {
      dispatch(fetchProductDetailsData({ productNames: productNames }));
    } else {
      dispatch(
        setOpenToast({
          openToast: true,
          severity: TOAST_SEVERITY.ERROR,
          message: t("dnet.no_info_for_comparison.text"),
        })
      );
    }
  };

  return (
    <>
      <div>
      { parent!=="globalSearch" && (
      <>
        <Typography
          variant="h5"
          className={styles.header}
          pb={3}
          color="primary"
        >
          {!_.isEmpty(productContentData?.displayItem) ? (
              productContentData?.displayItem?.length > 1 ? (
                <>
                  <div>{t("dnet.product_multiple_series.text")}</div>
                  <span className={styles.multiProds}>
                    {productContentData?.displayItem.map((category:any)=>`${findDisplayName(category)}, `)}
                  </span>
                </>
              ) : (
                findDisplayName(productContentData?.displayItem.toString())
              )
            ) : (
              t("dnet.products.section")
            )}
        </Typography>
        {prodContentData?.length > 0 && (
          <div className={styles.tableFooter}>
            <div className={styles.totalAmount}>
              {showCompare && (
                <CustomButton
                  label={t("dnet.compare_button.text")}
                  color="primary"
                  onClick={() => redirectToCompare()}
                  disabled={selectedProducts?.length < 2}
                />
              )}
              <CustomButton
                label={t("dataDownload.download.title")}
                color="primary"
                onClick={() => redirectToDataDownload()}
              />
            </div>
          </div>
        )}
        <br />
        {showCompare && (
          <div>
            <Typography variant="h6" color="text.primary">
              {t("dnet.comparison_wizard_title.text")}
            </Typography>

            <Typography variant="subtitle2" color="text.secondary">
              {t("dnet.comparison_explanation.text")}
            </Typography>
          </div>
        )}
        <br />
        </>
      )}
        <Grid container spacing={3}>
          {prodContentData?.map((product: any, index: any) => {
            return (
              <Grid
                sx={{}}
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                key={index}
              >
                <Card
                  sx={{ display: "flex" }}
                  elevation={2}
                  className={styles.card}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <br />
                    <CardMedia
                      sx={{ width: 99, marginLeft: 4 }}
                      className={styles.cardMedia}
                      component="img"
                      height={82}
                      image={
                        product?.product?.index?.thumbnail
                          ? product?.product?.index?.thumbnail
                          : noProduct
                      }
                      alt={product?.product?.index?.displayName}
                    />
                  </Box>
                  <Box sx={{ height: 100 }}>
                    <Checkbox
                      size="small"
                      sx={{ marginLeft: 1 }}
                      onChange={(e: any) => {
                        checkHandler(e, product, index);
                      }}
                    />
                    <Link
                      style={{ cursor: "pointer" }}
                      underline="hover"
                      onClick={() => {
                        redirectToDetails(product);
                      }}
                    >
                      <CardContent className={styles.cardContent}>
                        <Typography
                          gutterBottom
                          variant="subtitle2"
                          component="div"
                        >
                          {product?.product?.index?.displayName}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {product?.product?.index?.headlineDescription
                            ? descriptionHanlder(
                                product?.product?.index?.headlineDescription
                              )
                            : t("dnet.empty_description.txt")}
                        </Typography>
                      </CardContent>
                    </Link>
                    <CardActions>
                      <Button
                        onClick={() => {
                          redirectToDetails(product);
                        }}
                        size="medium"
                      >
                        <Typography variant="caption">
                          {t("dnet.view_product.text")}
                        </Typography>
                      </Button>
                    </CardActions>
                  </Box>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </>
  );
};
